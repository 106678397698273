/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable max-len */

import { AxiosPromise, AxiosRequestConfig } from 'axios'
import Axios from './axios'
import { ObjectWithId } from './react-query/interfaces/mynt'
import {
  AccountDto,
  AccountXYear,
  ActivationGuidePatchDto,
  Amount,
  AttestStatus,
  BackOfficeAccountPatchDto,
  BackOfficeAgreementTypeV6,
  BackOfficeAPIApiFactory,
  BackOfficeCardCreditPatchV2Dto,
  BackOfficeCreatePaymentForCustomerRequestDto,
  BackOfficeCustomerCreateDtoV2,
  BackOfficeCustomerPatchDtoV2,
  BackOfficeInvoicePatchDto2,
  BackOfficeInvoicePdfResponseDto2,
  BackofficeInvoicingConfigPatchDto,
  BackOfficePatchPaymentRequestDto,
  BackOfficePayoutCreateDtoV2,
  BackOfficePayoutDataResponseDtoV2,
  BackOfficePayoutPatchDtoV2,
  BoCompanyCreditRequestV2,
  BureauConfigRequest,
  BureauRelationshipRequest,
  BureauUnassingmentRequest,
  CardBalanceConfigPatchDto,
  CompanyCreditDto,
  CreateAgreementV6Request,
  CreateDirectDebitMandateV4Request,
  CreatePaymentRequestDtoV2,
  CreatePersonDto,
  EnfucePayoutState,
  FileContext,
  InvoiceState,
  InvoiceType,
  KycRisk,
  MonthlyReportRequest,
  PatchPaymentRequestDtoV2,
  PatchPrepaidResourceBody,
  PatchStandardResourceBody,
  PaymentRequestStatusDtoV2,
  PaymentState,
  PayoutsApiFactory,
  PersonDto,
  PersonPatchDto,
  QuoteUrlRequestDto,
  ResetRequest,
  TransactionFilters,
  VoucherDto
} from './swagger/definitions/backoffice'
import config from '../config'
import { SmsBody } from './react-query'
import { normalizeString } from '../helpers/common'

export const backofficeApi = BackOfficeAPIApiFactory(undefined, config.boBaseUrl, Axios)
const payoutsApi = PayoutsApiFactory(undefined, config.boBaseUrl, Axios)

// Verify payment
export const getPayment = (paymentId: string) => backofficeApi.getPayment(paymentId).then(({ data }) => data)
export const getPaymentReceiver = (paymentId: string) => backofficeApi.getPaymentReceiverV1(paymentId).then(({ data }) => data)
export const getPaymentWhitelist = (customerId: string) => backofficeApi.getPaymentWhiteListV1(customerId).then(({ data }) => data)
export const addPaymentToWhiteList = (paymentId: string) => backofficeApi.whiteListPaymentV1(paymentId).then(({ data }) => data)
export const removePaymentToWhiteList = (customerId: string, paymentWhitelistId: string) =>
  backofficeApi.deletePaymentWhiteListV1(customerId, paymentWhitelistId).then(({ data }) => data)

// bureau
export const bureauConfigure = (payload: BureauConfigRequest) => backofficeApi.configure(payload).then(({ data }) => data)
export const bureauAssign = (payload: BureauRelationshipRequest) => backofficeApi.assign(payload).then(({ data }) => data)
export const bureauUnassign = (payload: BureauUnassingmentRequest) => backofficeApi.unassign(payload).then(({ data }) => data)
export const bureauList = () => backofficeApi.list().then(({ data }) => data)
export const bureauReset = (payload: ResetRequest) => backofficeApi.resetBureau(payload).then(({ data }) => data)
export const bureauClients = (bureauId: string) => backofficeApi.bureauClients(bureauId).then(({ data }) => data)
export const bureau = (bureauId: string) => backofficeApi.bureau(bureauId).then(({ data }) => data)

export const recalculatePaumentRequests = async (creditId: string) => backofficeApi.postRecalculateV2(creditId).then(({ data }) => data)
export const createPaymentRequest = async (paymentRequestDto: CreatePaymentRequestDtoV2) =>
  backofficeApi.createV2(paymentRequestDto).then(({ data }) => data)
export const patchPaymentRequest = async (paymentRequestId: string, patchPaymentRequestDtoV2: PatchPaymentRequestDtoV2) =>
  backofficeApi.patchPaymentRequestV2(paymentRequestId, patchPaymentRequestDtoV2).then(({ data }) => data)

export const getCustomerLog = (customerId: string) => backofficeApi.log(customerId).then(({ data }) => data)
export const getCustomerNotes = (customerId: string) => backofficeApi.findBy(customerId).then(({ data }) => data)
export const getInvoiceConfig = async (customerId: string) => backofficeApi.getInvoicingConfigV1(customerId).then(({ data }) => data)
export const patchInvoiceConfig = async (customerId: string, payload: BackofficeInvoicingConfigPatchDto) =>
  backofficeApi.patchInvoicingConfigV1(customerId, payload).then(({ data }) => data)
export const patchCardV1 = async (
  customerId: string,
  cardId: string,
  { singleAmountLimit, dailySumAmount }: Partial<{ singleAmountLimit: number; dailySumAmount: number }>
) => backofficeApi.patchCardV1(customerId, cardId, { singleAmountLimit, _24HourSumAmount: dailySumAmount }).then(({ data }) => data)
export const getActivationGuide = async (customerId: string) => backofficeApi.getActivationGuide(customerId).then(({ data }) => data)
export const createSmsTemplate = async (body: Partial<SmsBody>) => backofficeApi.add(body).then(({ data }) => data)
export const deleteSmsTemplate = async (id: string) => backofficeApi._delete(id).then(({ data }) => data)
export const updateSmsTemplate = async (id: string, body: SmsBody) => backofficeApi.put(id, body).then(({ data }) => data)
export const sendSms = async (customerId: string, personId: string, text: string) =>
  backofficeApi.sendSms(customerId, personId, { text }).then(({ data }) => data)
export const getSmsTemplates = async () => backofficeApi.findAll().then(({ data }) => data)
export const search = async (query: string, maxRows?: number) => backofficeApi.search(query, maxRows).then(({ data }) => data)
export const getPresignedFileURL = async (customerId: string, fileId: string) =>
  backofficeApi.getPreSignedUrl(customerId, fileId).then(({ data }) => data)
export const syncScoringFile = async (customerId: string, id: string, context: FileContext) =>
  backofficeApi.syncFile(customerId, id, { context }).then(({ data }) => data)
export const getScoringFileUploadURL = async (customerId: string, context: FileContext) =>
  backofficeApi.addFile(customerId, { context }).then(({ data }) => data)
export const uploadScoringFile = async (url: string, file: File) =>
  (
    Axios.put(url, file, {
      headers: { 'Content-Type': file.type, 'Content-Disposition': `filename="${normalizeString(file.name)}"` }
    }) as AxiosPromise<any>
  ).then(({ data }) => data)
export const getCustomerFiles = async (customerId: string, contexts: string[]) =>
  backofficeApi.getFiles(customerId, contexts).then(({ data }) => data)
export const deleteCustomerFile = async (customerId: string, fileId: string) =>
  backofficeApi.deleteFile(customerId, fileId).then(({ data }) => data)
export const updateCardAccount = async (customerId: string, body: PatchPrepaidResourceBody) =>
  backofficeApi.patchAccount(customerId, body).then(({ data }) => data)
export const updateCustomerCardAccount = async (customerId: string, accountId: string, body: BackOfficeAccountPatchDto) =>
  backofficeApi.patchAccountV2(customerId, accountId, body).then(({ data }) => data)
export const allCreditsUsingGET = async (
  {
    customerId,
    level
  }: {
    customerId?: string
    level?: string
  },
  options?: any
) => backofficeApi.allCreditsV2(customerId, level, options).then(({ data }) => data)
export const generateCSVReport = async (onDate?: string) =>
  backofficeApi.generateCsvReport(onDate, { responseType: 'blob' }).then(({ data }) => data)
export const getRepaymentModels = async () => backofficeApi.getAllRepaymentModels().then(({ data }) => data)
export const getRepaymentInvoiceSummary = async (id: string) => backofficeApi.getRepaymentInvoiceSummary(id).then(({ data }) => data)
export const getAccountingYears = async () => backofficeApi.getYears().then(({ data }) => data)
export const getAccountingAccounts = async (year?: string) => backofficeApi.getAccounts(year).then(({ data }) => data)
export const getCustomerCardAccounts = async (customerId: string) => backofficeApi.getAccountsV2(customerId).then(({ data }) => data)
export const getVoucherResult = async (from?: string, to?: string, years?: string[], accounts?: number[]) =>
  backofficeApi.getVouchersResultReport(from, to, years, accounts).then(({ data }) => data)
export const getAllVouchers = async (from?: string, to?: string, years?: string[], accounts?: number[]) =>
  backofficeApi.getAllVouchers(from, to, years, accounts).then(({ data }) => data)
export const createVoucher = async (voucherDto: VoucherDto) => backofficeApi.createVoucher(voucherDto).then(({ data }) => data)
export const createAccountingYear = async (yearDto: AccountXYear) => backofficeApi.createYear(yearDto).then(({ data }) => data)
export const editAccountingYear = async (id: string, yearDto: AccountXYear) =>
  backofficeApi.updateYear(id, yearDto).then(({ data }) => data)
export const createAccountingAccount = async (accountDto: AccountDto) => backofficeApi.createAccount(accountDto).then(({ data }) => data)
export const updateAccountingAccount = async (id: string, accountDto: AccountDto) =>
  backofficeApi.updateAccount(id, accountDto).then(({ data }) => data)
export const deleteAccountingAccount = async (id: string) => backofficeApi.deleteAccount(id).then(({ data }) => data)
export const deleteAccountingYear = async (id: string) => backofficeApi.deleteYear(id).then(({ data }) => data)

export const updateKycPeople = async (customerId: string) => backofficeApi.updatePeople(customerId).then(({ data }) => data)
export const updateKycForPerson = async (customerId: string, ssn: string) =>
  backofficeApi.getKycForPerson(customerId, ssn).then(({ data }) => data)
export const getAllKycRoaring = async (customerId: string) => backofficeApi.allRoaringData(customerId).then(({ data }) => data)
export const getKycPeople = async (customerId: string) => backofficeApi.getPeople(customerId).then(({ data }) => data)
export const getActivationVisibility = async (customerId: string) =>
  backofficeApi.getActivationGuideVisibility(customerId).then(({ data }) => data)
export const patchActivationVisibility = async (customerId: string, activationGuidePatchDto: ActivationGuidePatchDto) =>
  backofficeApi.patchActivationGuideVisibility(customerId, activationGuidePatchDto).then(({ data }) => data)
export const getKycRisks = async (customerId: string) => backofficeApi.getRisk(customerId).then(({ data }) => data)
export const updateKycRisks = async (customerId: string, riskDto: KycRisk) =>
  backofficeApi.putRisk(customerId, riskDto as any).then(({ data }) => data)
export const getTransactions = async (
  customerId: string,
  { page, offset, limit }: { page?: number; offset?: number; limit?: number } = {}
) => backofficeApi.getTransactionsCombined(customerId, page, offset, limit).then(({ data }) => data)
export const updateCustomer = async (customerId: string, dto: BackOfficeCustomerPatchDtoV2) =>
  backofficeApi.patchCustomer(customerId, dto).then(({ data }) => data)
export const getCards = async (customerId: string) => backofficeApi.getCards(customerId).then(({ data }) => data)
export const getCardsV2 = async (customerId: string) => backofficeApi.cards(customerId).then(({ data }) => data)
export const getPrivateCustomerCard = async (customerId: string, cardId: string) =>
  backofficeApi.getCard(customerId, cardId).then(({ data }) => data)
export const getPrivateCustomerCardPlastics = async (customerId: string, cardId: string) =>
  backofficeApi.getCardPlastics(customerId, cardId).then(({ data }) => data)
export const getPrivateCustomerCardConfig = async (customerId: string, cardId: string) =>
  backofficeApi.getCardConfig(customerId, cardId).then(({ data }) => data)

export const activatePlastic = async (customerId: string, cardId: string, plasticId: string) =>
  backofficeApi.activatePlastic(customerId, cardId, plasticId).then(({ data }) => data)
export const getCardDeliveryAddress = async (customerId: string, personId: string) =>
  backofficeApi.cardDeliveryAddress(customerId, personId).then(({ data }) => data)

export const getQuoteUrl = async ({ creditId, quoteUrlRequestDto }: { creditId: string; quoteUrlRequestDto: QuoteUrlRequestDto }) =>
  backofficeApi.getQuoteUrl(creditId, quoteUrlRequestDto).then(({ data }) => data)
export const getAgreementDocument = async ({ id, orgNo, creditNo }: { id: string; orgNo: string; creditNo: string | number }) =>
  backofficeApi.getDocumentUrl(id, `${orgNo}_kreditavtal_${creditNo}.pdf`).then(({ data }) => data)

export const transactionTriggerBusinessEventUsingPOST = async (customerId: string, transactionId: string) =>
  backofficeApi.triggerBusinessEvent(customerId, transactionId).then(({ data }) => data)

export const getCustomerCreditOverrides = async (customerId: string) => backofficeApi.getCreditOverride(customerId).then(({ data }) => data)
export const setCustomerCreditOverrides = async (customerId: string, payload: Record<string, any>) =>
  backofficeApi.putCreditOverride(customerId, payload).then(({ data }) => data)
export const deleteCustomerCreditOverrides = async (customerId: string) =>
  backofficeApi.deleteCreditOverride(customerId).then(({ data }) => data)

export const patchPerson = async (body: Partial<any>, personId: string, customerId: string) =>
  backofficeApi.updatePerson(customerId, personId, body).then(({ data }) => data)
export const getKYCResponses = async (customerId: string) => backofficeApi.getKycList(customerId).then(({ data }) => data)
export const postPEReports = async (customerId: string, body: MonthlyReportRequest) => backofficeApi.sendMonthlyReport(customerId, body)
export const getPEReports = async (customerId: string) => backofficeApi.getAllMonthlyReports(customerId).then(({ data }) => data)

export const getPersonsWithRolesUsingGET = async (customerId: string) =>
  backofficeApi.getPersonsWithRoles(customerId).then(({ data }) => data as Required<PersonDto>[])
export const getPeopleByCustomerId = getPersonsWithRolesUsingGET

export const createCustomer = async (customerDto: BackOfficeCustomerCreateDtoV2) =>
  backofficeApi.createCustomer(customerDto).then(({ data }) => data)
export const patchCardConfig = async (customerId: string, cardId: string, config: Record<string, unknown> | any) =>
  backofficeApi.patchCardConfig(customerId, cardId, config).then(({ data }) => data)
export const reissueCard = async (customerId: string, cardId: string) =>
  backofficeApi.reissueCard(customerId, cardId).then(({ data }) => data)
export const resetCardPin = async (customerId: string, cardId: string) =>
  backofficeApi.resetPinAttempt(customerId, cardId).then(({ data }) => data)
export const patchCard = async (customerId: string, cardId: string, req: PatchStandardResourceBody) =>
  backofficeApi.patchCard(customerId, cardId, req).then(({ data }) => data)
export const patchCardBalanceConfigUsingPATCH = async (req: CardBalanceConfigPatchDto, customerId: string) =>
  backofficeApi.patchCardBalanceConfig(customerId, req).then(({ data }) => data)
export const getOrCreateCardBalanceConfigUsingGET = async (customerId: string) =>
  backofficeApi.getCardBalanceConfig(customerId).then(({ data }) => data)
export const getAllEnfucePayoutsV2 = async (customerId?: string, states?: EnfucePayoutState[]) =>
  payoutsApi.getAllPayoutsV2(customerId, states).then(({ data }) => data) as Promise<BackOfficePayoutDataResponseDtoV2[]>
export const createEnfucePayoutUsingPOST = async (req: BackOfficePayoutCreateDtoV2) =>
  payoutsApi.createPayoutV2(req).then(({ data }) => data)
export const patchEnfucePayoutV2 = async ({ payoutId, payout }: { payoutId: string; payout: BackOfficePayoutPatchDtoV2 }) =>
  payoutsApi.patchPayoutV2(payoutId, payout).then(({ data }) => data) as Promise<BackOfficePayoutPatchDtoV2>
export const patchPaymentUsingPATCH = async ({ paymentId, payout }: { paymentId: string; payout: BackOfficePatchPaymentRequestDto }) =>
  backofficeApi.patchPayment(paymentId, payout).then(({ data }) => data) as Promise<BackOfficePatchPaymentRequestDto>
export const getPaymentsUsingGET = async (state?: PaymentState[]) => backofficeApi.getPayments(state).then(({ data }) => data)

export const directLoginTokenUsingPOST = async (customerId: string, personId: string) =>
  backofficeApi.directLoginToken(customerId, personId).then(({ data }) => data as unknown as string)
export const getCardCreditsUsingGET = async (customerId: string) => backofficeApi.getCardCreditsV2(customerId).then(({ data }) => data)

export const patchCardCreditUsingPATCH = async (customerId: string, creditId: string, req: BackOfficeCardCreditPatchV2Dto, options?: any) =>
  backofficeApi.patchCardCreditV2(customerId, creditId, req, options).then(({ data }) => data)

export const getInvoicesV2 = async (
  customerId?: string,
  {
    type,
    state,
    from,
    to,
    settleFrom,
    settleTo
  }: Partial<{
    type: InvoiceType[]
    state: InvoiceState[]
    from: string
    to: string
    settleFrom: string
    settleTo: string
  }> = {}
) => backofficeApi.getInvoicesV2(customerId, type, state, from, to, settleFrom, settleTo).then(({ data }) => data)
export const createInvoiceV2 = async (invoice: Parameters<typeof backofficeApi.createInvoiceV2>[0]) =>
  backofficeApi.createInvoiceV2(invoice).then(({ data }) => data)
export const updateInvoiceV2 = async (invoiceDto: BackOfficeInvoicePatchDto2 & ObjectWithId) =>
  backofficeApi.updateInvoiceV2(invoiceDto.id, invoiceDto).then(({ data }) => data)
export const getDefaultPenaltyFeeV2 = async (customerId: string) =>
  backofficeApi.getDefaultPenaltyFeeV2(customerId).then(({ data }) => data)

export const findCustomerUsingGET2 = async (customerId: string) => backofficeApi.findCustomerById(customerId).then(({ data }) => data)
export const getDownloadablePdfUrl = async (url: string) => Axios.get<BackOfficeInvoicePdfResponseDto2>(url).then(({ data }) => data)
export const deletePersonUsingDELETE = async (customerId: string, personId: string) =>
  backofficeApi.deletePerson(customerId, personId).then(({ data }) => data)
export const deleteCustomerUsingDELETE = async (customerId: string) => backofficeApi.deleteCustomer(customerId)
export const registerExternalRepayment = async (req: BackOfficeCreatePaymentForCustomerRequestDto) =>
  backofficeApi.createPayment({ ...req }).then(({ data }) => data)
export const updatePersonUsingPATCH = async (customerId: string, personId: string, personDto: PersonPatchDto) =>
  backofficeApi.updatePerson(customerId, personId, personDto).then(({ data }) => data)
export const createPersonUsingPOST = async (customerId: string, personDto: CreatePersonDto) =>
  backofficeApi.createPersonWithRoles(customerId, personDto).then(({ data }) => data)
export const sendKYCEmail = async (customerId: string, personId: string) =>
  backofficeApi.person(customerId, personId).then(({ data }) => data)
export const sendKYCApprovedEmail = async (customerId: string) => backofficeApi.kycApproved(customerId).then(({ data }) => data)
export const createNote = async (customerId: string, text: string) => backofficeApi.add1(customerId, { text }).then(({ data }) => data)
export const updateNote = async (customerId: string, noteId: string, text: string, rev: number) =>
  backofficeApi.put1(customerId, noteId, { text, rev }).then(({ data }) => data)
export const deleteNote = async (customerId: string, noteId: string) => backofficeApi.delete1(customerId, noteId).then(({ data }) => data)
export const generateKycReport = async (customerId: string) => backofficeApi.generateKycReport(customerId).then(({ data }) => data)
export const getCreditSnapshots = async (dates: string[]) => backofficeApi.getSnapshots(dates).then(({ data }) => data)
export const snoozeInboxItem = async (itemId: string, until: string) => backofficeApi.addSnooze(itemId, { until }).then(({ data }) => data)
export const unSnoozeInboxItem = async (itemId: string) => backofficeApi.deleteSnooze(itemId).then(({ data }) => data)
export const getInbox = async () => backofficeApi.getInbox().then(({ data }) => data)

// Credits
export const createCompanyCredit = async (customerId: string, loan: BoCompanyCreditRequestV2, options?: any) =>
  backofficeApi.postNewCompanyCreditRequestV2({ ...loan, customerId }, options).then(({ data }) => data)
export const getCompanyCredits = async (creditId: string, options?: any) =>
  backofficeApi.getCompanyCredit(creditId, options).then(({ data }) => data)
export const updateCompanyCredits = async (creditId: string, credit: Partial<CompanyCreditDto>) =>
  backofficeApi.updateCompanyCredit(creditId, credit).then(({ data }) => data)
export const getCreditReport = async (creditId: string, options?: any) =>
  backofficeApi.getCreditReport(creditId, options).then(({ data }) => data)
export const setStandardTerms = async (id: string) => backofficeApi.setStandardTermsCustomerCredit(id).then(({ data }) => data)
export const regenerateAgreementDocument = async (id: string) => backofficeApi.regenerateAgreementDocument(id).then(({ data }) => data)
export const regenerateRepaymentPlan = async (id: string) => backofficeApi.regenerateRepaymentPlan(id).then(({ data }) => data)
export const approveCredit = async (id: string) => backofficeApi.approveCredit(id).then(({ data }) => data)
export const repayCredit = async (id: string) => backofficeApi.markCreditAsRepaid(id).then(({ data }) => data)
export const defaultCredit = async (id: string) => backofficeApi.markCreditAsDefault(id).then(({ data }) => data)
export const closeCredit = async (id: string) => backofficeApi.closeCredit(id).then(({ data }) => data)
export const netCredit = async (id: string) => backofficeApi.markCreditAsNetted(id).then(({ data }) => data)
export const payoutCompanyCredit = async (id: string, amountDto: Amount) =>
  backofficeApi.payoutCompanyCredit(id, amountDto).then(({ data }) => data)
export const getCompanyCreditInvoices = async (id: string) =>
  backofficeApi.getRepaymentInvoicesForCompanyCredit(id).then(({ data }) => data)
export const syncCompanyCreditWithFortnox = async (id: string) => backofficeApi.syncCompanyCreditWithFortnox(id).then(({ data }) => data)
export const getRepaymentPlan = async (creditId: string) => backofficeApi.fetchRepaymentPlan(creditId).then(({ data }) => data)
export const updateRepayment = async (creditId: string, no: number, planId: string, values: any) =>
  backofficeApi.updateRepayment(creditId, planId, no, values).then(({ data }) => data)
export const dropInvoiceFromRepayment = async (creditId: string, no: number, planId: string) =>
  backofficeApi.dropInvoiceFromRepayment(creditId, planId, no).then(({ data }) => data)
export const attachInvoiceToRepayment = async (creditId: string, planId: string, no: number, body: any) =>
  backofficeApi.attachInvoiceToRepayment(creditId, planId, no, body).then(({ data }) => data)
export const recreateRepaymentInvoice = async (creditId: string, planId: string, no: number) =>
  backofficeApi.reCreateRepaymentInvoice(creditId, planId, no).then(({ data }) => data)
export const createRepayment = async (creditId: string, planId: string, values: any) =>
  backofficeApi.createRepayment(creditId, planId, values).then(({ data }) => data)
export const rebuildInvoicesAndRepaymentPlanFromFortnox = async (creditId: string, planId: string) =>
  backofficeApi.rebuildInvoicesAndRepaymentPlanFromFortnox(creditId, planId)
export const getLoanAllInvoices = async (creditId: string) => backofficeApi.getAllRepaymentInvoices(creditId).then(({ data }) => data)

export const getLoanInvoice = async (creditId: string) => backofficeApi.getRepaymentInvoice(creditId).then(({ data }) => data)
export const createRepaymentInvoiceBooked = async (invoiceId: string) =>
  backofficeApi.bookRepaymentInvoice(invoiceId).then(({ data }) => data)
export const createRepaymentInvoiceCancelled = async (invoiceId: string) =>
  backofficeApi.cancelRepaymentInvoice(invoiceId).then(({ data }) => data)
export const createRepaymentInvoiceDraft = async (invoiceId: string) =>
  backofficeApi.createDraftRepaymentInvoice(invoiceId).then(({ data }) => data)
export const createRepaymentInvoiceCredited = async (invoiceId: string) =>
  backofficeApi.creditRepaymentInvoice(invoiceId).then(({ data }) => data)
export const createRepaymentInvoiceDebtCollection = async (invoiceId: string) =>
  backofficeApi.debtCollectRepaymentInvoice(invoiceId).then(({ data }) => data)
export const createRepaymentInvoiceReminder = async (invoiceId: string, amount: Amount) =>
  backofficeApi.remindRepaymentInvoice(invoiceId, amount).then(({ data }) => data)

export const syncInvoicesWithFortnox = async (invoiceId: string) => backofficeApi.syncWithFortnox(invoiceId).then(({ data }) => data)
export const getCredit = async (creditId: string) => backofficeApi.getCredit(creditId).then(({ data }) => data)

export const getAllAgreementsV6 = async (customerId: string, agreementType: BackOfficeAgreementTypeV6[]) =>
  backofficeApi.getAllAgreementsV6(customerId, agreementType).then(({ data }) => data)
export const createAgreementV6 = async (customerId: string, agreementDto: CreateAgreementV6Request) =>
  backofficeApi.createAgreementV6(customerId, agreementDto).then(({ data }) => data)

export const syncCards = async (customerId: string, cardId: string) => backofficeApi.syncCard(customerId, cardId).then(({ data }) => data)

export const getDirectDebitMandatesV4 = async (customerId: string) =>
  backofficeApi.getDirectDebitMandatesV4(customerId).then(({ data }) => data)
export const createDirectDebitMandateV4 = async (customerId: string, req: CreateDirectDebitMandateV4Request) =>
  backofficeApi.createDirectDebitMandateV4(customerId, req)
export const syncDirectDebitMandateV4 = async (customerId: string, mandateId: string) =>
  backofficeApi.syncDirectDebitMandateV4(customerId, mandateId).then(({ data }) => data)

export const getPreferredMandateV4 = async (customerId: string) =>
  backofficeApi.getPreferredDirectDebitMandateV4(customerId).then(({ data }) => data)
export const getRefreshAddress = async (customerId: string) => backofficeApi.refreshAddress(customerId).then(({ data }) => data)

export const cancelDirectDebitMandateV4 = async (customerId: string, mandateId: string) =>
  backofficeApi.cancelDirectDebitMandateV4(customerId, mandateId).then(({ data }) => data)
export const resendDirectDebitMandateV4 = async (customerId: string, mandateId: string) =>
  backofficeApi.resendDirectDebitMandateV4(customerId, mandateId).then(({ data }) => data)

export const sendWelcomeEmail = async (customerId: string) => backofficeApi.sendWelcomeEmail(customerId).then(({ data }) => data)

// Auth
export const signInWithGoogle = (redirect: string) =>
  backofficeApi.googleOAuthRedir(redirect, {
    //@ts-expect-error, the return type is not correct
    adapter: ({ baseURL, url }) => window.open(`${baseURL}${url}`, '_self')
  })

export const getSignInType = (axiosOptions?: AxiosRequestConfig) => backofficeApi.isLoggedIn(axiosOptions).then(({ data }) => data)
export const signOut = () => backofficeApi.logout().then(({ data }) => data)

// Dev
export const switchToDevSalesRole = () =>
  window.open(`${config.boBaseUrl}/bo/dev-switch-to-sales?callback=${window.location.href}`, '_self')
export const switchToDevAdminRole = () =>
  window.open(`${config.boBaseUrl}/bo/dev-switch-to-backoffice?callback=${window.location.href}`, '_self')

// Big boy requests
export const getTransactionsV2 = async (
  customerId: string,
  {
    offset,
    limit,
    from,
    to,
    cardIds,
    exclude,
    attestStatus,
    max,
    min,
    onlyinclude,
    personIds,
    query
  }: {
    offset?: number
    limit?: number
    from?: string
    to?: string
    min?: number
    max?: number
    onlyinclude?: TransactionFilters[]
    exclude?: TransactionFilters[]
    attestStatus?: AttestStatus[]
    query?: string
    personIds?: string[]
    cardIds?: string[]
  } = {}
) =>
  backofficeApi
    .getBoTransactionsV2(customerId, offset, limit, from, to, min, max, onlyinclude, exclude, attestStatus, query, personIds, cardIds)
    .then(({ data }) => data)

export const getPaymentRequests = async (
  customerId: string | undefined,
  filters: {
    cardCreditId?: string
    currency?: string[]
    hasPaidEnough?: boolean
    status?: PaymentRequestStatusDtoV2[]
    dueDateFrom?: string
    dueDateTo?: string
    paymentRequestDateFrom?: string
    paymentRequestDateTo?: string
    periodFrom?: string
    periodTo?: string
  },
  options?: any
) =>
  backofficeApi
    .getPaymentRequestsV2(
      customerId,
      filters.cardCreditId,
      filters.currency,
      filters.hasPaidEnough,
      filters.status,
      filters.dueDateFrom,
      filters.dueDateTo,
      filters.paymentRequestDateFrom,
      filters.paymentRequestDateTo,
      filters.periodFrom,
      filters.periodTo,
      options
    )
    .then(({ data }) => data)
