/* eslint-disable no-console */
import LoaderRenderProp from '../components/LoaderRenderProp'
import React from 'react'
import { getAgreementDocument, getQuoteUrl } from '../api'
import { usePersons } from '../api/react-query'
import Person from '../interfaces/person'
import CreditAgreements from './CreditAgreements'
import useMaterialNotification from 'hooks/useMaterialNotification'

interface IProps {
  orgno: string
  agreementDocumentId: string
  creditId: string
  creditNo: string | number
  showQuotes: boolean
  customerId: string
}

const copy = (text: string) => window.navigator.clipboard.writeText(text)

const openPdf = async (url: string) =>
  fetch(url, { credentials: 'include' })
    .then(async (r) => r.blob())
    .then((blob) => window.open(URL.createObjectURL(blob), '_BLANK'))

const CreditAgreementsContainer: React.FC<IProps> = ({ orgno, agreementDocumentId, creditId, creditNo, showQuotes, customerId }) => {
  const { data: persons = [], isLoading } = usePersons(customerId)
  const notify = useMaterialNotification()

  const onDocumentClick = async () => {
    const data = await getAgreementDocument({
      id: agreementDocumentId,
      orgNo: orgno,
      creditNo
    })

    openPdf(data)
  }

  const onQuoteUrlClick = (ssn: string, personId: string) => async () => {
    const data = await getQuoteUrl({ creditId, quoteUrlRequestDto: { personId } })

    notify(`Quote URL copied to clipboard`, 'info')

    copy(data.url || '')
  }

  return (
    <LoaderRenderProp
      showLoader={isLoading}
      render={() => (
        <CreditAgreements
          signatories={persons as unknown as Person[]}
          handleOnDocumentClick={onDocumentClick}
          handleOnQuoteUrlClick={onQuoteUrlClick}
          showQuotes={showQuotes}
        />
      )}
    />
  )
}

export default CreditAgreementsContainer
