import styled from '@emotion/styled'
import Cancel from '@mui/icons-material/Cancel'
import Event from '@mui/icons-material/Event'
import DatePicker from '@mui/lab/DatePicker'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip, { ChipProps } from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import withStyles from '@mui/styles/withStyles'
import dayjs from 'dayjs'
import { isObject, isString } from 'lodash'
import get from 'lodash/get'
import React, { ChangeEvent, FocusEventHandler, HTMLInputTypeAttribute, useCallback, useRef } from 'react'
import { Control, Controller, FieldValues, Path, PathValue, UseControllerProps, useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Colors from '../figma/panda/Colors'
import Spacings from '../figma/tokens/Spacings'
import useLanguage from '../flamingo/hooks/useLanguage'
import FigmaBox from '../mynt-components/components/FigmaBox'
import { createAdornment } from '../mynt-components/components/MaterialFieldFormik'
import MaterialSwitchExperimental from '../mynt-components/components/MaterialSwitchExperimental'
import MyntLoader from '../mynt-components/components/MyntLoader'
import { YYYY_MM_DD } from '../tiger/Constants'
import { AntiloopTextType, Language } from '../tiger/interfaces/Antiloop'
import { getText } from '../tiger/libs/TextRepository'
import FeatherIcon from 'feather-icons-react'
import IconButton from '@mui/material/IconButton'

const hiddenLabelStyle = { visibility: 'hidden', maxWidth: '0.01px' }

const createAdornmentWithLoading = (adornment: JSX.Element, loading?: boolean) => {
  if (!loading) return <FigmaBox style={{ padding: '0.5rem 0.75rem' }}>{adornment}</FigmaBox>

  return (
    <FigmaBox direction="row" align="center" justify="center">
      <MyntLoader variant="small" />
      <FigmaBox style={{ padding: '0.5rem 0.75rem' }}>{adornment}</FigmaBox>
    </FigmaBox>
  )
}

const TextFieldStyled = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      flex: '100%'
    },
    '& .MuiInputBase-formControl.MuiInputBase-multiline': {
      padding: 0
    }
  }
})(TextField) as typeof TextField

export function TextFieldController<T extends Record<string, any>>({
  name,
  control,
  labelTextKey,
  labelText,
  multiline,
  adornment,
  onBlur,
  type,
  disabled,
  rows,
  loading,
  noLabel,
  InputProps,
  autoFocus = false,
  smallCaseInputProps
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: AntiloopTextType
  multiline?: boolean
  adornment?: JSX.Element
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  type?: HTMLInputTypeAttribute
  disabled?: boolean
  rows?: number
  loading?: boolean
  labelText?: string
  noLabel?: boolean
  InputProps?: TextFieldProps['InputProps']
  autoFocus?: boolean
  smallCaseInputProps?: TextFieldProps['inputProps']
}) {
  const language = useLanguage()

  const getLabelText = (textkey?: AntiloopTextType, text?: string) => {
    if (!text && textkey) return getText(textkey, language)

    return text
  }

  const inputProps = adornment ? { ...InputProps, endAdornment: createAdornmentWithLoading(adornment, loading) } : InputProps

  return (
    <Controller
      render={({ field: { onChange, value, onBlur: hookBlur }, formState: { errors } }) => {
        const handleBlur = (e) => {
          onBlur?.(e)
          hookBlur()
        }

        return (
          <TextFieldStyled
            autoFocus={autoFocus}
            rows={rows}
            disabled={disabled}
            fullWidth
            value={value}
            onBlur={handleBlur}
            inputProps={smallCaseInputProps}
            onChange={(event) => {
              if (type === 'number') {
                const value = event.target.value.includes('.') ? parseFloat(event.target.value) : parseInt(event.target.value)

                return onChange(value as PathValue<T, Path<T>>)
              }

              return onChange(event)
            }}
            label={!noLabel && getLabelText(labelTextKey, labelText)}
            error={Boolean(get(errors, name)?.message)}
            helperText={get(errors, name)?.message}
            multiline={multiline}
            type={type}
            InputProps={inputProps}
            sx={{ legend: noLabel ? hiddenLabelStyle : null }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

export function NumberFormatController<T extends Record<string, any>>({
  name,
  control,
  labelTextKey,
  adornment,
  disabled,
  onBlur,
  loading,
  noLabel,
  labelText
}: {
  name: Path<T>
  control: Control<T>
  labelTextKey?: AntiloopTextType
  adornment?: JSX.Element
  disabled?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  loading?: boolean
  noLabel?: boolean
  labelText?: string
}) {
  const language = useLanguage()

  let label = labelTextKey ? getText(labelTextKey, language) : ''

  if (labelText) {
    label = labelText
  }

  if (noLabel) label = ''
  return (
    <Controller
      render={({ field: { value, onChange, onBlur: hookBlur }, formState: { errors } }) => {
        const handleBlur = (e) => {
          onBlur?.(e)
          hookBlur()
        }

        const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
          e.preventDefault()
          const { clipboardData } = e
          const pastedData = clipboardData.getData('Text')
          const processedData = pastedData.replace(',', '.').replace(/\s+/g, '')

          const parsedValue = parseFloat(processedData)
          if (!isNaN(parsedValue)) onChange(parsedValue as PathValue<T, Path<T>>)
        }

        return (
          <NumberFormat
            onBlur={handleBlur}
            fullWidth
            value={value}
            disabled={disabled}
            decimalSeparator="."
            thousandSeparator=" "
            allowNegative={true}
            isNumericString={true}
            fixedDecimalScale={true}
            allowEmptyFormatting={true}
            allowedDecimalSeparators={['.', ',']}
            customInput={TextFieldStyled}
            error={Boolean(get(errors, name)?.message)}
            helperText={get(errors, name)?.message}
            label={label}
            InputProps={adornment ? { endAdornment: createAdornmentWithLoading(adornment, loading) } : {}}
            onPaste={onPaste}
            onValueChange={({ floatValue }) => {
              if (floatValue === undefined) {
                return onChange(null as PathValue<T, Path<T>>)
              }
              return onChange(floatValue as PathValue<T, Path<T>>)
            }}
            sx={{ legend: noLabel ? hiddenLabelStyle : null }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

type DatePickerControllerProps = {
  disabled?: boolean
  onChange?: (value: dayjs.Dayjs | null) => void
  placeholderTextKey?: AntiloopTextType
  placeholderText?: string
  labelTextKey?: AntiloopTextType
  labelText?: string
  disableRef?: boolean
  disablePast?: boolean
  disableFuture?: boolean
  readOnly?: boolean
}

const getTextContent = (textType: AntiloopTextType | string | undefined, language: Language) => {
  if (isObject(textType)) {
    return getText(textType, language)
  }

  if (isString(textType)) {
    return textType
  }

  return ''
}

export function DatePickerControllerV2<T extends object>({
  name,
  control,
  onChange: handleChange,
  placeholderTextKey,
  placeholderText,
  labelTextKey,
  labelText,
  disabled,
  disableRef,
  disablePast,
  disableFuture,
  readOnly
}: UseControllerProps<T> & DatePickerControllerProps) {
  const language = useLanguage()

  const {
    field: { onChange, ref, value, ...field },
    fieldState: { error }
  } = useController({ name, control, shouldUnregister: false })

  const dateOfBirthFormatFormValue = (date: dayjs.Dayjs) => date.format(YYYY_MM_DD)

  return (
    <DatePicker
      {...field}
      value={value}
      readOnly={readOnly}
      inputRef={disableRef ? undefined : ref}
      onChange={(value) => {
        let formattedValue: string | dayjs.Dayjs = value as dayjs.Dayjs
        if (value !== null) formattedValue = dateOfBirthFormatFormValue?.(value as dayjs.Dayjs) ?? (value as dayjs.Dayjs)
        onChange(formattedValue as any)
        handleChange?.(dayjs(formattedValue))
      }}
      inputFormat={YYYY_MM_DD}
      renderInput={(props) => (
        <StyledTextField
          fullWidth
          {...props}
          placeholder={placeholderText ? placeholderText : getTextContent(placeholderTextKey, language)}
          label={labelText ? labelText : getTextContent(labelTextKey, language)}
          error={Boolean(error?.message)}
          helperText={error?.message}
        />
      )}
      disablePast={disablePast}
      disableFuture={disableFuture}
      disabled={disabled}
    />
  )
}

const StyledTextField = withStyles({
  root: {
    backgroundColor: Colors.baseWhite,
    '& .MuiOutlinedInput-root': {
      flex: '100%'
    },
    '& .MuiFormHelperText-root': {
      margin: '3px 0 0 3px'
    },
    '& .MuiIconButton-root': {
      marginRight: '4px'
    }
  }
})(TextField) as typeof TextField

export const DatePickerController = <T extends Record<string, any>>({
  name,
  disabled,
  icon: Icon = Event,
  labelTextKey,
  inputFormat,
  control,
  readOnly,
  loading,
  noLabel
}: {
  name: Path<T>
  value?: dayjs.Dayjs | null
  onChange?: (value: dayjs.Dayjs) => unknown
  disabled?: boolean
  labelTextKey: AntiloopTextType
  control: Control<T>
  icon?: React.ElementType
  inputFormat?: string
  readOnly?: boolean
  loading?: boolean
  noLabel?: boolean
}) => {
  const language = useLanguage()

  const IconWithLoading = useCallback(() => createAdornmentWithLoading(<Icon />, loading), [])

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <DesktopDatePicker
          readOnly={readOnly}
          disabled={disabled}
          label={!noLabel && getText(labelTextKey, language)}
          InputProps={{ notched: !noLabel }}
          renderInput={(params) => <TextField {...params} />}
          onChange={(event) => onChange(event as PathValue<T, Path<T>>)}
          value={value}
          inputFormat={inputFormat}
          components={{
            OpenPickerIcon: IconWithLoading
          }}
        />
      )}
      control={control}
      name={name}
    />
  )
}

type Options = { value: string; label: string; disabled?: boolean }[]

export function AutoCompleteController<T extends Record<string, any>>({
  name,
  control,
  placeholderTextKey,
  placeholderText,
  options,
  onClose,
  disabled,
  label,
  onChange: _onChange,
  clearable
}: {
  name: Path<T>
  control: Control<T>
  placeholderTextKey?: AntiloopTextType
  placeholderText?: string
  options: Options
  onClose?: () => void
  disabled?: boolean
  label?: string
  onChange?: () => void
  clearable?: boolean
}) {
  const language = useLanguage()

  let placeHolder = placeholderTextKey ? getText(placeholderTextKey, language) : undefined
  let sxHideLabel: object | undefined = {
    '& legend': { display: 'none' },
    '& fieldset': { top: 0 }
  }

  if (placeholderText) {
    placeHolder = placeholderText
  }

  if (Boolean(placeHolder || label)) {
    sxHideLabel = undefined
  }

  return (
    <Controller
      render={({ field: { onChange, value = '' }, formState: { errors } }) => {
        const option = options?.find((option) => {
          if (typeof option.value === 'object') {
            return JSON.stringify(option.value) === JSON.stringify(value)
          }
          return option.value === value
        }) || { label: '', value: '' }

        return (
          <Autocomplete
            fullWidth
            value={option}
            options={options}
            onClose={onClose}
            disabled={disabled}
            disableClearable={true}
            getOptionLabel={(option) => option.label}
            sx={{ '.MuiOutlinedInput-root': { flex: '100%', padding: '0 8px !important' } }}
            onChange={(event, { value = '' }) => {
              onChange(value as PathValue<T, Path<T>>)
              _onChange?.()
            }}
            isOptionEqualToValue={(option, value) => {
              if (typeof option.value === 'object') {
                return value.value === '' || JSON.stringify(option.value) === JSON.stringify(value.value)
              }
              return value.value === '' || option.value === value.value
            }}
            renderInput={(props) => (
              <TextField
                {...props}
                label={label}
                placeholder={placeHolder}
                error={Boolean(errors[name]?.message)}
                helperText={errors[name]?.message}
                sx={{ height: '48px' }}
                InputProps={{
                  ...props.InputProps,
                  endAdornment: clearable ? (
                    <IconButton
                      onClick={() => {
                        onChange('' as PathValue<T, Path<T>>)
                        _onChange?.()
                      }}
                      size="small"
                    >
                      <FeatherIcon icon="x" />
                    </IconButton>
                  ) : null
                }}
              />
            )}
            renderOption={(props, _option) => {
              if (option.value === '') props['aria-selected'] = false

              return (
                <li {...props} key={props.id}>
                  {_option.label}
                </li>
              )
            }}
          />
        )
      }}
      control={control}
      name={name}
    />
  )
}

const StyledChipSelectLabel = styled(InputLabel)``

const StyledChipSelect = styled(Select)<{ hasValue?: boolean }>`
  padding: ${({ hasValue }) => (hasValue ? '0px' : '5px')};
`

export function SelectChipController<T extends FieldValues>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  adormentText,
  adormentTextKey,
  loading,
  noLabel,
  onBlur,
  onChipRemove,
  mapValueLabel,
  onChange,
  chipProps
}: {
  control: Control<T>
  adormentTextKey?: AntiloopTextType
  adormentText?: string
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: AntiloopTextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<any>) => void
  onChange?: (e: string[]) => void
  loading?: boolean
  noLabel?: boolean
  options: { value: string | number; label: string }[]
  onChipRemove?: (value: string) => void
  mapValueLabel?: (value: any[]) => string[]
  chipProps?: ChipProps
}) {
  const language = useLanguage()
  const selectRef = useRef<any>(null)

  return (
    <Controller
      render={({ field: { onChange: hookChange, value } }) => (
        <FormControl fullWidth>
          <StyledChipSelectLabel id={`${name}Label`}>
            {!noLabel && (labelText ? labelText : labelTextKey && getText(labelTextKey, language))}
          </StyledChipSelectLabel>
          <StyledChipSelect
            hasValue={!!(value as string[]).length}
            onBlur={onBlur}
            inputRef={selectRef}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            labelId={`${name}Label`}
            label={!noLabel && (labelText ? labelText : labelTextKey && getText(labelTextKey, language))}
            value={mapValueLabel?.(value as any) ?? value}
            multiple
            style={{ height: 'auto' }}
            onChange={(event: any) => {
              if (onChange) return onChange(event.target.value)

              return hookChange(event as ChangeEvent<Element>)
            }}
            endAdornment={createAdornment(adormentTextKey, adormentText, loading, true)}
            sx={{ legend: noLabel ? hiddenLabelStyle : null }}
            renderValue={(values) => {
              const selected = values as string[]

              return (
                <FigmaBox direction="row" flexWrap="wrap" gap={Spacings.minimum}>
                  {selected.map((selectedValue) => (
                    <Chip
                      {...chipProps}
                      clickable={!disabled}
                      deleteIcon={
                        <Cancel
                          onMouseDown={(event) => {
                            event.stopPropagation()

                            // When stopping the propagation the components looses its focus state
                            // we need to refocus it after a slight delay
                            setTimeout(() => selectRef.current.focus(), 10)
                          }}
                        />
                      }
                      onDelete={(e) => {
                        e?.stopPropagation()

                        if (disabled) return

                        if (onChipRemove) {
                          return onChipRemove(selectedValue)
                        }

                        ;(onChange || hookChange)(value.filter((v) => v !== selectedValue))
                      }}
                      key={selectedValue}
                      label={options.find((v) => v.value === selectedValue)?.label ?? selectedValue}
                    />
                  ))}
                </FigmaBox>
              )
            }}
          >
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledChipSelect>
        </FormControl>
      )}
      control={control}
      name={name}
    />
  )
}

export function SelectController<T extends Record<string, any>>({
  name,
  control,
  options,
  disabled,
  labelTextKey,
  labelText,
  defaultValue,
  adormentText,
  adormentTextKey,
  loading,
  onBlur,
  readOnly,
  noLabel,
  onChange: _onChange,
  inheritBackgroundColor = true
}: {
  control: Control<T>
  adormentTextKey?: AntiloopTextType
  adormentText?: string
  defaultValue?: string[]
  disabled?: boolean
  labelText?: string
  labelTextKey?: AntiloopTextType
  name: Path<T>
  onBlur?: (e: React.ChangeEvent<any>) => void
  onChange?: (...event: any[]) => void
  loading?: boolean
  options: { value: string | number; label: string; disabled?: boolean }[]
  onChipRemove?: (value: any) => void
  readOnly?: boolean
  noLabel?: boolean
  inheritBackgroundColor?: boolean
}) {
  const language = useLanguage()
  const selectRef = useRef<any>(null)

  return (
    <Controller
      render={({ field: { onChange, value = [] } }) => (
        <FormControl fullWidth>
          <InputLabel id={`${name}Label`}>
            {!noLabel && (labelText ? labelText : labelTextKey && getText(labelTextKey, language))}
          </InputLabel>
          <Select
            readOnly={readOnly}
            onBlur={onBlur}
            inputRef={selectRef}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            labelId={`${name}Label`}
            label={!noLabel && (labelText ? labelText : labelTextKey && getText(labelTextKey, language))}
            value={value}
            style={{ height: 'auto' }}
            onChange={(event) => {
              onChange(event as ChangeEvent<Element>)
              _onChange?.(event)
            }}
            endAdornment={createAdornment(adormentTextKey, adormentText, loading, true)}
            sx={{ legend: noLabel ? hiddenLabelStyle : null, backgroundColor: inheritBackgroundColor ? 'inherit' : Colors.baseWhite }}
          >
            {options.map(({ value, label, disabled }) => (
              <MenuItem key={value} value={value} disabled={disabled}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      control={control}
      name={name}
    />
  )
}

export function CheckboxController<T extends Record<string, any>>({
  name,
  control,
  onChange: _onChange,
  labelTextKey,
  labelPlacement = 'end',
  justifyContent = 'flex-start',
  disabled,
  style
}: {
  name: Path<T>
  control: Control<T>
  onChange?: (checked: boolean) => void
  labelTextKey?: AntiloopTextType
  labelPlacement?: FormControlLabelProps['labelPlacement']
  justifyContent?: React.CSSProperties['justifyContent']
  disabled?: boolean
  style?: React.CSSProperties
}) {
  const language = useLanguage()

  return (
    <FormControlLabel
      style={style}
      labelPlacement={labelPlacement}
      label={labelTextKey ? getText(labelTextKey, language) : ''}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <Checkbox
              disabled={disabled}
              checked={Boolean(value)}
              onChange={(event, checked) => {
                onChange(event)
                _onChange && _onChange(checked)
              }}
            />
          )}
          control={control}
          name={name}
        />
      }
      sx={{ marginRight: 0, marginLeft: 0, justifyContent }}
    />
  )
}

export function SwitchController<T extends Record<string, any>>({
  name,
  control,
  onBlur,
  labelTextKey,
  labelPlacement = 'end',
  justifyContent = 'flex-start',
  labelText,
  disabled,
  onChange: _onChange
}: {
  name: Path<T>
  control: Control<T>
  onBlur?: () => void
  labelTextKey?: AntiloopTextType
  labelPlacement?: FormControlLabelProps['labelPlacement']
  justifyContent?: React.CSSProperties['justifyContent']
  disabled?: boolean
  labelText?: string | React.ReactNode
  onChange?: () => void
}) {
  const language = useLanguage()

  let label: any = labelTextKey ? getText(labelTextKey, language) : ''

  if (labelText) label = labelText

  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      label={label}
      control={
        <Controller
          render={({ field: { onChange, value } }) => (
            <MaterialSwitchExperimental
              disabled={disabled}
              checked={Boolean(value)}
              onChange={(e) => {
                onChange(e)
                _onChange?.()
              }}
              onBlur={onBlur}
            />
          )}
          control={control}
          name={name}
        />
      }
      sx={{ marginRight: 0, marginLeft: 0, justifyContent }}
    />
  )
}
